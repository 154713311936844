import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`How does your skill compare?`}</h2>
    <p>{`We recently analyzed the Alexa Skill ratings in the Alexa store.`}</p>
    <p>{`Of the over 40,000 skills in the Alexa Store, nearly 62% have no ratings at all.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/10/15141255/alexa-ratings-all.png",
        "alt": null
      }}></img></p>
    <p>{`Looking closer, the majority of one-star and five-star reviews come from one rating. In fact, 78% of the one-star reviews and 65% of the five-star reviews are from one review.`}</p>
    <p>{`If we remove all the skills with only one review, we start to see a clearer distribution of ratings. However, 97% of the one-star ratings still come from three or less reviews and 95% of five-star ratings come from five or fewer reviews.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/10/15141253/alexa-ratings-1.png",
        "alt": null
      }}></img></p>
    <p>{`Upon closer analysis, 95% of skills have eight reviews or less. Removing all the skills with eight or fewer reviews, results in a much clearer distribution of ratings. We start to see two peaks emerge – one around 2.8 stars and another in the mid to upper 4s.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/10/15141254/alexa-ratings-8.png",
        "alt": null
      }}></img></p>
    <h2>{`Popular Categories`}</h2>
    <p>{`The most common types of skills in the Alexa Store are Games, Education, and Music. Games are by far the most common with nearly 4,000 more skills than the next two categories. Interestingly, when we `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2017/11/21/the-impact-of-alexa-and-google-home-on-consumer-behavior/"
      }}>{`surveyed users about their behaviors`}</a>{`, only 30% used the devices for Games, but 56% of those users played multiple times a day.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/10/15141256/alexa-top-cats.png",
        "alt": null
      }}></img></p>
    <p>{`In regards to popularity, Music and Business categories get the highest ratings on average – slightly higher than four-star ratings each. Games average about 3.5 stars and Utilities have the least with about 2.8 stars on average. These ratings are about the same as what `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2018/05/02/alexa-skill-usage-continues-to-rise/"
      }}>{`we saw when reviewing back in May`}</a>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/10/15141251/alexa-categories-ratings.png",
        "alt": null
      }}></img></p>
    <h2>{`Recommended skills`}</h2>
    <p>{`Looking at Amazon’s own recommendations, the ratings tend to be higher on average. The Alexa staff’s recommendations tend to average ~4 stars or more. In fact, the “Staff Picks” category is 4.1 stars as is the “Alexa, what are your top skills?” category. The smart home skills tend to average lower ratings at approximately 3 stars. Interestingly enough, there are two categories for latest skills – “Latest Skills” and “Alexa, what are your latest skills” – and they have different average ratings – nearly a half star difference.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/10/15141252/alexa-categories-recommended-update.png",
        "alt": null
      }}></img></p>
    <h2>{`Ratings are important`}</h2>
    <p>{`Store rankings can be a significant contributor to user acquisition. In a `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2017/11/21/the-impact-of-alexa-and-google-home-on-consumer-behavior/"
      }}>{`previous survey`}</a>{` we conducted, nearly 40% of users discover new skills through the store. In addition, both brands and startups have found the position within the Alexa Store has a significant impact – citing that if the skill falls off the first page, they’re basically lost. Having a strategy to increase positive reviews, and thus store position, can result in higher user acquisition.`}</p>
    <h2>{`About Dashbot`}</h2>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://www.dashbot.io"
        }}>{`Dashbot`}</a>{` is a conversational analytics platform that enables enterprises and developers to increase engagement, acquisition, and monetization through actionable data and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including NLP response effectiveness, sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support Facebook Messenger, Alexa, Google Home, Slack, Twitter, Kik, SMS, web chat, and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      